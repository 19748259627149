import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateConversion',
  standalone: true
})
export class DateConversionPipe implements PipeTransform {
  transform(value: string | number | Date, option?: string): string {
    const style = 'short';
    const timeOptionsMs: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const date = new Date(value);
    switch (option) {
      case 'date':
        return date.toLocaleDateString(navigator.language, { timeZoneName: style });
      case 'time':
        return date.toLocaleTimeString(navigator.language, { timeZoneName: style });
      case 'time_ms':
        return date.toLocaleTimeString(navigator.language, timeOptionsMs);
      case 'iso':
        return date.toLocaleString(navigator.language, { timeZoneName: style,  timeZone: 'UTC' });
      case 'iso_ms':
        return (
          date.toLocaleDateString(navigator.language, { timeZoneName: style, timeZone: 'UTC' }) +
          ', ' +
          date.toLocaleTimeString(navigator.language, { ...timeOptionsMs, timeZone: 'UTC' })
        );
      default:
        return date.toLocaleString(navigator.language, { timeZoneName: style});
    }
  }
}
