import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { DateConversionPipe } from '../../pipes/date-conversion.pipe';
import { IncidentsService } from '../../services/incidents.service';
import { SessionResponse } from '../../models/session-response';
import { AuthenticationService } from '../../auth/authentication.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { firstValueFrom } from 'rxjs';
import { GeotabLcService } from '../../geotab-lc.service';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlingComponent } from '../error-handling/error-handling.component';
import { AuthResponse } from '../../models/auth-response';
import {DurationPipe} from '../../pipes/duration.pipe';
import {DecimalPipe} from '@angular/common';

@Component({
  selector: 'app-smoke-incident',
  templateUrl: './smoke-incident.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    DateConversionPipe,
    NgxSpinnerModule,
    NgbModalModule,
    DurationPipe,
    DecimalPipe
  ],
  styleUrl: './smoke-incident.component.scss'
})
export class SmokeIncidentComponent implements OnInit, OnDestroy {
  smokeEvents: any;
  sessionResponse: SessionResponse;
  @Input() filter?: string;

  constructor(private readonly incidentService: IncidentsService,
    private readonly authenticationService: AuthenticationService,
    private readonly spinnerService: NgxSpinnerService,
    public geotabLcService: GeotabLcService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly modalService: NgbModal) { }

  lifeCycleHandler = null;

  /* istanbul ignore next */
  async ngOnInit(): Promise<void> {
    this.lifeCycleHandler = this.geotabLcService.geotablc$.subscribe(async (lifecycle) => {
      console.log('Lifecycle', lifecycle);
      if (lifecycle === 'initialized') {
        await this.authenticate();
        await this.loadIncidents();
      }
    });

    await this.loadIncidents();
  }

  /* istanbul ignore next */
  async loadIncidents() {
    const authToken = sessionStorage.getItem('authToken_' + sessionStorage.getItem('database'));
    if (authToken) {
      try {
        this.spinnerService.show();
        if (this.filter) {
          await this.getIncidents(this.filter);
        } else {
          this.smokeEvents = await firstValueFrom(this.incidentService.getSmokeIncidents());
          this.cdRef.detectChanges();
        }
      } catch (error) {
        if (error.status) {
          const modalRef = this.modalService.open(ErrorHandlingComponent, {
            centered: true,
            backdropClass: 'backdrop-class',
          });
          modalRef.componentInstance.error = error;
        }
      } finally {
        this.spinnerService.hide();
      }
    }
    else {
      console.info('No auth token found, waiting for user to authenticate...');
    }
  }

  /* istanbul ignore next */
  ngOnDestroy(): void {
    this.lifeCycleHandler.unsubscribe();
  }

  /* istanbul ignore next */
  openDashboard(incident) {
    this.incidentService.openDashboard(incident);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    this.incidentService.openReport(incidentId);
  }

  /* istanbul ignore next */
  async authenticate() {
    this.spinnerService.show();
    const res = sessionStorage.getItem('ridecare_' + sessionStorage.getItem('database'));
    this.sessionResponse = JSON.parse(res);
    try {
      const authResponse: AuthResponse = await firstValueFrom(this.authenticationService.getOAuthToken(this.sessionResponse));
      sessionStorage.setItem('authToken_' + sessionStorage.getItem('database'), authResponse.authenticationResult.accessToken);
      sessionStorage.setItem('refreshToken_' + sessionStorage.getItem('database'), authResponse.authenticationResult.refreshToken);
      sessionStorage.setItem('tenant_' + sessionStorage.getItem('database'), authResponse.tenant);
      this.smokeEvents = await firstValueFrom(this.incidentService.getSmokeIncidents());
      this.cdRef.detectChanges();
    } catch (error: any) {
      if (error.status) {
        const modalRef = this.modalService.open(ErrorHandlingComponent, {
          centered: true,
          backdropClass: 'backdrop-class',
        });
        modalRef.componentInstance.error = error;
      }
    } finally {
      this.spinnerService.hide();
    }
  }

  /* istanbul ignore next */
  async getIncidents(licencePlate?: string): Promise<void> {
    this.smokeEvents = await firstValueFrom(this.incidentService.getSmokeIncidents(licencePlate))
    this.cdRef.detectChanges();
  }
}
