import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IncidentsService {

  damageResponse = {
    damageIncidents: [
      {
        tenant: "TEST_TENANT",
        messageId: "42babf35-dc44-4dc8-8ec9-e2b86f836d13",
        deviceId: "ivs_slimscaley_prod_746129014691b1fbddfa4cd064f8ceed43692833",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1731047513737,
        comment: null,
        incidentStatus: "DAMAGE_VISIBLE",
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_UNKNOWN",
        vin: "2C3CDXHG7PH647419",
        serialNumber: "746129014691b1f",
        licencePlate: "01DWBT"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "d82e0bee-a358-4bf5-ad50-974c1295a60d",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728390164118,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "52700851-fdc7-4af8-8d8d-1d1fc24bee10",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728389864076,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "a133ed65-7127-4ffb-b5e5-2cb1ec2121ac",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728389565851,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "14790f2e-59ba-4ec6-9426-5c5e3d91ee2d",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728389265950,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "82ccf942-a02a-4d13-9dd1-2bad973d36a4",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728388963896,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "846756a1-6499-4950-99bf-c47b7fba8a60",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728388664130,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "9d9b95c9-5c29-4dc2-b151-d37354cef0da",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728388365831,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      },
      {
        tenant: "TEST_TENANT",
        messageId: "e54b558a-b110-4cfc-8d6d-502c5b4b39b3",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.damage.DamageEvent",
        timestamp: 1728388065888,
        comment: null,
        severity: "CLASS_SEVERE_DAMAGE",
        damageLocation: "LOC_FRONT_LEFT",
        vin: "unknownvin",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133"
      }
    ]
  }
  smokeResponse = {
    incidents: [
      {
        tenant: "TEST_TENANT",
        messageId: "7b7893d0-8a62-4d58-a6b0-52b8ae02f101",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1733216324390,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "38947856-ca1a-4855-8a64-dc587fe9bd64",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1732714528513,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "5bab8458-bdfb-4fbe-ad02-aaa98c79ddbb",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1732537672286,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "cea107d2-8132-4564-835a-1f96c6507266",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1732251756869,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "c383a2c9-0f01-47f0-8ae1-b5d5b8ac9287",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731911452215,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "7a17d836-ad8b-494d-a4eb-b7d3f42a1591",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731588060458,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "c0326b5b-31ab-49a9-a047-7fb7f87748ab",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731532687579,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "bee220db-3d88-41de-a848-bff1a6ae6d3a",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731378684845,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "60384d23-07c5-406c-b7b4-410b01fff04d",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731330951344,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      },
      {
        tenant: "TEST_TENANT",
        messageId: "f19aa5ca-a8e4-402d-be12-e89aa4bfc569",
        deviceId: "CANARY_AM_DEV_02",
        incidentType: "com.bosch.ivs.SmokeEvent",
        timestamp: 1731111457032,
        comment: null,
        severity: "CLASS_UNKNOWN",
        damageLocation: "LOC_UNKNOWN",
        vin: "JTDKN3DU5A0060673",
        serialNumber: "CANARY_AM_DEV_02",
        licencePlate: "S-T 133",
        confidence: 0.3
      }
    ]
  }

  getSmokeIncidents(){
    return this.smokeResponse;
  }

  getDamageIncidents(){
    return this.damageResponse;
  }

  /* istanbul ignore next */
  openDashboard(incident) {
    window.open(`${environment.hostV2}/admin/incident-overview/detail/`+ incident.vin + '/' + incident.messageId);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    window.open(`${environment.apiReports}/download/${incidentId}`);
  }
}
