import { Routes } from '@angular/router';
import {AdminComponent} from './components/admin/admin.component';

export const routes: Routes = [
  {
    path: 'incident-overview',
    component: AdminComponent
  },
  { path: '**',
    redirectTo: '/incident-overview'
  },
];
