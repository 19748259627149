import {Component, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {SmokeEvent} from '../../models/smoke-event';
import {DateConversionPipe} from '../../pipes/date-conversion.pipe';
import {IncidentsService} from '../../services/incidents.service';
import {RouterLink} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-smoke-incident',
  templateUrl: './smoke-incident.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    DateConversionPipe
  ],
  styleUrl: './smoke-incident.component.scss'
})
export class SmokeIncidentComponent implements OnInit{

  smokeEvents : SmokeEvent[];

  constructor(private readonly incidentService: IncidentsService) {}


  ngOnInit(): void {
    this.smokeEvents = this.incidentService.getSmokeIncidents().incidents;
  }
  /* istanbul ignore next */
  openDashboard(incident) {
    this.incidentService.openDashboard(incident);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    this.incidentService.openReport(incidentId);
  }
}
