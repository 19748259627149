<div
  class="m-dialog m-dialog--warning"
  id="frontend-kit-dialog-alert-dialog-warning"
  role="dialog"
  aria-labelledby="frontend-kit-dialog-alert-dialog-warning-title"
>
  <div class="a-box -floating">
    <div class="m-dialog__remark --warning"></div>
    <div class="m-dialog__header">
      <i class="a-icon ui-ic-alert-warning" title="warning"></i>
      <div class="m-dialog__title">Authentication Error</div>
      <button
        type="button"
        class="a-button a-button--integrated -without-label"
        data-frok-action="close"
        aria-label="close dialog"
        (click)="activeModal.close()"
      >
        <i class="a-icon a-button__icon ui-ic-close" title="Lorem Ipsum"></i>
      </button>
    </div>
    <hr class="a-divider" />
    <div class="m-dialog__content">
      <div class="m-dialog__body" id="error-message">
        {{error.error?.message}}
      </div>
      <br>
      <div class="m-dialog__body" id="contact-info">
        Please contact <a href="mailto: support.ridecare@bosch.com">{{ 'APP.email' | translate }}</a> for further assistance.
      </div>
    </div>
  </div>
</div>
