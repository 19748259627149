import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class IncidentsService {

  constructor(public httpClient: HttpClient) {}

  /* istanbul ignore next */
  private buildQueryParams(params?: string, incidentType?: string): HttpParams {
    const tenant = sessionStorage.getItem('tenant_'+sessionStorage.getItem('database')) || '';
    let queryParams = new HttpParams()
      .set('tenant', tenant)
      .set('incidentType', incidentType)
      .set('limit', '10')
      .set('skip', '0');

    if (params) {
      queryParams = queryParams.append('licencePlate', params);
    }

    return queryParams;
  }

  /* istanbul ignore next */
  getSmokeIncidents(params?: string): Observable<any> {
    const url = `${environment.dashboardURL}/dashboard/incidents`;
    const queryParams = this.buildQueryParams(params, 'com.bosch.ivs.SmokeEvent');
    return this.httpClient.get(url, { params: queryParams });
  }

  /* istanbul ignore next */
  getDamageIncidents(params?: string): Observable<any> {
    const url = `${environment.dashboardURL}/dashboard/incidents`;
    const queryParams = this.buildQueryParams(params, 'com.bosch.ivs.damage.DamageEvent');
    return this.httpClient.get(url, { params: queryParams });
  }

  /* istanbul ignore next */
  openDashboard(incident) {
    window.open(`${environment.hostV2}/admin/incident-overview/detail/` + incident.vin + '/' + incident.messageId);
  }

  /* istanbul ignore next */
  openReport(incidentId) {
    window.open(`${environment.apiReports}/download/${incidentId}`);
  }
}
