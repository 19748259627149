import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number): string {
    console.log('initial value' + value);
    if (value < 0) {
      return `Can't format negative duration of ${value} seconds.`;
    }
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    console.log('value:' + minutes+':'+ seconds);
    return this.formatWithZeros(minutes) + ':' + this.formatWithZeros(seconds);
  }

  formatWithZeros(value: number): string {
    return value < 10 ? ('00' + value).slice(-2) : value.toString();
  }

}
