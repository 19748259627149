  <div class="e-container" style="padding-top: 2rem; max-width: 25rem; margin: auto 10%;">
    <main style="padding-top: 4rem">
      <div class="a-text">
        <h5>Enter Licence Plate</h5>
      </div>
      <div class="a-search-input">
        <input type="search" id="search-input" name="licencePlate" placeholder="e.g.: S T 123"/>
        <button
          type="submit"
          class="a-search-input__icon-search"
        >
          <i class="a-icon ui-ic-search"></i>
        </button>
        <button type="button" class="a-search-input__icon-close">
          <i class="a-icon ui-ic-close-small"></i>
        </button>
      </div>
    </main>
  </div>
  <div class="e-container" style="margin: auto 10%">
    <main style="padding-top: 3rem">
      <div class="a-text" style="padding-bottom: 1rem">
        <h5>Incident history</h5>
      </div>
        <ul class="a-option-bar">
          <li class="a-option-bar__item">
            <input type="radio" id="1" name="demo-options-with-labels" (change)="filterEvents(toggleButtons[0].name)" [checked]="isSmokeIncident"/>
            <label class="a-option-bar__option" for="1" aria-label="Option 1">
              <span class="a-option-bar__label">Smoke Incidents</span>
            </label>
          </li>
          <li class="a-option-bar__item">
            <input type="radio" id="2" name="demo-options-with-labels" (change)="filterEvents(toggleButtons[1].name)"/>
            <label class="a-option-bar__option" for="2" aria-label="Option 2">
              <span class="a-option-bar__label">Damage Incidents</span>
            </label>
          </li>
        </ul>
    </main>

  </div>
  <div class="e-container" style="max-width: unset; margin: auto 10%">
    <app-smoke-incident *ngIf="isSmokeIncident"></app-smoke-incident>
    <app-damage-incident *ngIf="isDamageIncident"></app-damage-incident>
  </div>
