import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-error-handling',
  imports: [TranslatePipe],
  templateUrl: './error-handling.component.html'
})
export class ErrorHandlingComponent{
  @Input() error;
  constructor(public activeModal: NgbActiveModal) {
  }
}
