import {GeotabLcService} from './geotab-lc.service';
import {Component, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Router, RouterOutlet} from '@angular/router';

declare var geotab: any;

@Component({
  selector: 'app-root',
  imports: [TranslateModule, RouterOutlet],
  templateUrl: './app.component.html',
  standalone: true,
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'rc_geotab_addon';
  constructor(private readonly translate: TranslateService,
              public router: Router, public geotabLcService: GeotabLcService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  /* istanbul ignore next */
  ngOnInit(): void {
    // This value is set in the localstorage by GeoTab
    if(localStorage.getItem('logout_database')){
      this.removeSessionStorage(localStorage.getItem('logout_database'));
    }
    geotab.addin.rc = () => {
      return {
        initialize: (api: any, state: any, callback: () => void) =>{
          console.log('Add-in is initialized.');
          api.getSession((session : any) => {
            console.log('sessionId retrieved on initialize');
            sessionStorage.setItem(`ridecare_${session.database}`, JSON.stringify(session));
            sessionStorage.setItem('database', session.database);
            // we'll update the lifecycle to initialized only after retrieving the session id
            this.geotabLcService.updateLifecycle('initialized');
          })
          callback();
        },
        focus: (api: any, state: any) => {
          console.log('Add-in is focused');
          api.getSession((session: any) => {
            // only set session id if session is not empty
            if (session.id) {
              console.log('sessionId retrieved on focus');
              sessionStorage.setItem(`ridecare_${session.database}`, JSON.stringify(session));
            }
            sessionStorage.setItem('database', session.database);
          })
          this.geotabLcService.updateLifecycle('focused');
        },
        blur: (api: any, state: any) => {
          api.getSession((session: any) => {
            console.log('Add-in is blurred');
            this.removeSessionStorage(session.database);
            this.geotabLcService.updateLifecycle('blured');
          });
        }
      }
    }
  }

  /* istanbul ignore next */
  removeSessionStorage(database: string): void {
    sessionStorage.removeItem(`ridecare_${database}`);
    sessionStorage.removeItem(`authToken_${database}`);
    sessionStorage.removeItem(`refreshToken_${database}`);
    sessionStorage.removeItem(`tenant_${database}`);
  }
}
