import {NgIf} from '@angular/common';
import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {DamageIncidentComponent} from '../damage-incident/damage-incident.component';
import {SmokeIncidentComponent} from '../smoke-incident/smoke-incident.component';
import {FormsModule} from '@angular/forms';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-incident-overview',
  templateUrl: './incident-overview.component.html',
  standalone: true,
  styleUrl: './incident-overview.component.scss',
  imports: [
    SmokeIncidentComponent,
    NgIf,
    DamageIncidentComponent,
    TranslatePipe,
    FormsModule,
  ],
})

export class IncidentOverviewComponent implements OnInit {
  @ViewChild(SmokeIncidentComponent) smokeIncidentComponent: SmokeIncidentComponent;
  @ViewChild(DamageIncidentComponent) damageIncidentComponent: DamageIncidentComponent;

  SMOKE_EVENT = 'com.bosch.ivs.SmokeEvent';
  DAMAGE_EVENT = 'com.bosch.ivs.damage.DamageEvent';
  toggleButtons = [
    { id: 1, name: this.SMOKE_EVENT },
    { id: 2, name: this.DAMAGE_EVENT },
  ];
  isSmokeIncident: boolean;
  isDamageIncident: boolean;
  licencePlate: string = '';
  private readonly licencePlateSubject = new Subject<string>();

  /* istanbul ignore next */
  ngOnInit(): void {
    this.isSmokeIncident = true;
    this.licencePlateSubject.subscribe(() => {
      this.searchLicencePlateEvents();
    });
  }

  /* istanbul ignore next */
  filterEvents(event: string) {
    if (event === this.SMOKE_EVENT) {
      this.isDamageIncident = false;
      this.isSmokeIncident = true;
      this.searchLicencePlateEvents();
    } else {
      this.isDamageIncident = true;
      this.isSmokeIncident = false;
      this.searchLicencePlateEvents();
    }
  }

  /* istanbul ignore next */
  searchLicencePlateEvents(): void {
    if (this.isSmokeIncident && this.smokeIncidentComponent) {
      this.smokeIncidentComponent.getIncidents(this.licencePlate);
    } else if (this.isDamageIncident && this.damageIncidentComponent) {
      this.damageIncidentComponent.getIncidents(this.licencePlate);
    }
  }

  /* istanbul ignore next */
  onLicencePlateChange(value: string) {
    this.licencePlateSubject.next(value);
  }
}
