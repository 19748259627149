<div>
  <table class="m-table" aria-label="Highlights" style="width: calc(100% - 4rem)">
    <thead>
    <tr>
      <th><span class="table-header">{{ "DAMAGE_EVENT.dateTime" | translate }}</span></th>
      <th><span class="table-header">{{ "DAMAGE_EVENT.licencePlate" | translate }}</span></th>
      <th><span class="table-header">{{ "DAMAGE_EVENT.severity" | translate }}</span></th>
      <th><span class="table-header">{{ "DAMAGE_EVENT.impactArea" | translate }}</span></th>
      <th><span class="table-header">{{ "DAMAGE_EVENT.incidentId" | translate }}</span></th>
      <th><span class="table-header">{{ "DAMAGE_EVENT.reports" | translate }}</span></th>
    </tr>
    </thead>
    <tbody>
    <tbody>
      @for (incident of damageEvents?.incidents; track incident.messageId) {
        <tr>
          <td><span> {{ incident.timestamp | dateConversion }}</span></td>
          <td><span> {{ incident.licencePlate }}</span></td>
          <td><span> {{ "DAMAGE_EVENT." + incident.severity | translate }}</span></td>
          <td><span> {{ "DAMAGE_EVENT." + incident.damageLocation | translate }}</span></td>
          <td>
            <span>
              <a (click)="openDashboard(incident)">
                <i class="a-icon ui-ic-inline-externallink"></i>
                {{ incident.messageId }}
              </a>
            </span>
          </td>
          <td>
            <span>
              <a (click)="openReport(incident.messageId)">
                <i class="a-icon ui-ic-inline-externallink"></i>
                {{ 'APP.pdfReport' | translate }}
              </a>
            </span>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<ngx-spinner
  fullScreen="true"
  bdColor="rgba(216,216,216,0.8)"
  template="<div class='a-activity-indicator'>
            <div class='a-activity-indicator__top-box'></div>
            <div class='a-activity-indicator__bottom-box'></div>
            </div>"
></ngx-spinner>
