<div>
  <table class="m-table" aria-label="Highlights" style="width: calc(100% - 4rem)">
    <thead>
    <tr>
      <th>
        <span class="table-header"> {{ "SMOKE_EVENT.dateTime" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.licencePlate" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.classification" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.duration" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.totalSmoke" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.incidentId" | translate }} </span>
      </th>
      <th>
        <span class="table-header">{{ "SMOKE_EVENT.reports" | translate }}</span>
      </th>
    </tr>
    </thead>
    <tbody>
      @for (incident of smokeEvents?.incidents; track incident.messageId) {
        <tr>
          <td><span> {{ incident.timestamp | dateConversion }}</span></td>
          <td><span> {{ incident.licencePlate }}</span></td>
          <td><span> {{ "SMOKE_EVENT."+ incident?.classification | translate }}</span></td>
          <td><span> {{ incident?.durationInSeconds | duration }} {{"SMOKE_EVENT.min" | translate}}</span></td>
          <td><span> {{ incident.totalSmoke | number : '1.0-2' }} {{"SMOKE_EVENT.unit" | translate}}</span></td>
          <td>
            <span>
              <a (click)="openDashboard(incident)">
                <i class="a-icon ui-ic-inline-externallink"></i>
                {{ incident.messageId }}
              </a>
            </span>
          </td>
          <td>
            <span>
              <a (click)="openReport(incident.messageId)">
                <i class="a-icon ui-ic-inline-externallink"></i>
                {{ 'APP.pdfReport' | translate }}
              </a>
            </span>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<ngx-spinner
  fullScreen="true"
  bdColor="rgba(216,216,216,0.8)"
  template="<div class='a-activity-indicator'>
            <div class='a-activity-indicator__top-box'></div>
            <div class='a-activity-indicator__bottom-box'></div>
            </div>"
></ngx-spinner>
