import {HttpInterceptorFn} from '@angular/common/http';
import {Observable, switchMap} from 'rxjs';
import {jwtDecode} from 'jwt-decode';
import {SessionResponse} from '../models/session-response';
import {AuthenticationService} from './authentication.service';
import {inject} from '@angular/core';
import {AuthResponse} from '../models/auth-response';
import {NgxSpinnerService} from 'ngx-spinner';

/* istanbul ignore next */
export const JwtInterceptor: HttpInterceptorFn = (req, next) => {
  let authenticationService = inject(AuthenticationService);
  let spinnerService = inject(NgxSpinnerService);
  const accessToken = sessionStorage.getItem('authToken_'+sessionStorage.getItem('database'));
  const payload = JSON.parse(sessionStorage.getItem('ridecare_'+sessionStorage.getItem('database')));

  if (req.url.includes('assets/i18n/')) {
    return next(req);
  }

  if (!accessToken) {
    return next(req);
  }

  const decodedToken = jwtDecode(accessToken);
  const expirationTime = decodedToken?.exp * 1000;
  const currentTime = Date.now();

  if (expirationTime < currentTime && payload ) {
    spinnerService.show();
    return requestNewAccessToken(payload, authenticationService).pipe(
      switchMap((authResponse: AuthResponse) => {
        sessionStorage.setItem('authToken_'+sessionStorage.getItem('database'), authResponse.authenticationResult.accessToken);
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authResponse.authenticationResult.accessToken}`
          }
        });

        return next(clonedRequest);
      }),
    );
  }

  const clonedRequest = req.clone({
    setHeaders: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return next(clonedRequest);
};

/* istanbul ignore next */
function requestNewAccessToken(payload: SessionResponse, authenticationService: AuthenticationService): Observable<AuthResponse> {
  sessionStorage.removeItem('authToken_'+sessionStorage.getItem('database'));

  return authenticationService.getOAuthToken(payload).pipe(
    switchMap((authResponse: AuthResponse) => {
      return new Observable<AuthResponse>(observer => {
        observer.next(authResponse);
        observer.complete();
      });
    })
  );
}
