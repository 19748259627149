import {Component, Input, OnInit} from '@angular/core';
import {TranslatePipe} from '@ngx-translate/core';
import {DateConversionPipe} from '../../pipes/date-conversion.pipe';
import {IncidentsService} from '../../services/incidents.service';
import {NgxSpinnerModule, NgxSpinnerService} from 'ngx-spinner';
import {firstValueFrom} from 'rxjs';
import {ErrorHandlingComponent} from '../error-handling/error-handling.component';
import {NgbModal, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-damage-incident',
  templateUrl: './damage-incident.component.html',
  standalone: true,
  imports: [
    TranslatePipe,
    DateConversionPipe,
    NgxSpinnerModule,
    NgbModalModule
  ],
  styleUrl: './damage-incident.component.scss'
})

export class DamageIncidentComponent implements OnInit{

  damageEvents;
  @Input() filter?: string;

  constructor(private readonly incidentService: IncidentsService,
              private readonly spinnerService: NgxSpinnerService,
              private readonly modalService: NgbModal) {
  }

  /* istanbul ignore next */
  async ngOnInit(): Promise<void> {
    this.spinnerService.show();
    try {
      if (this.filter) {
        await this.getIncidents(this.filter);
      } else {
        this.damageEvents = await firstValueFrom(this.incidentService.getDamageIncidents());
      }
    } catch (error) {
      if(error.status) {
        const modalRef = this.modalService.open(ErrorHandlingComponent, {
          centered: true,
          backdropClass: 'backdrop-class',
        });
        modalRef.componentInstance.error = error;
      }
    } finally {
      this.spinnerService.hide();
    }
  }

  /* istanbul ignore next */
  openDashboard(incident: any) {
    this.incidentService.openDashboard(incident);
  }

  /* istanbul ignore next */
  openReport(incidentId: any) {
    this.incidentService.openReport(incidentId);
  }

  /* istanbul ignore next */
  async getIncidents(licencePlate?: string): Promise<void> {
    this.damageEvents = await firstValueFrom(this.incidentService.getDamageIncidents(licencePlate))
  }
}
